<template>
  <div class="apply">
    <div class="news-container">
      <div class="industry">
        <div class="industry-li">
          <div class="industry-li-img droductNewsBox">
            <img src="@/assets/img/news/20201019140707601.jpg" alt="" />
          </div>
          <div class="txt">
            <div class="time">
              <span>28</span>
              2020-12
            </div>
            <h2>
              <router-link
                class="dot yichu1"
                to="/"
                title="Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020."
              >
                Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial
                vehicles ,these black technologies in the Spring Festival
                Evening of 2020.</router-link
              >
            </h2>
            <p class="dot3">
              Every Spring Festival Gala on New Year's Eve is an appetizing
              "audiovisual dinner" on the audience's dinner table. In recent
              years, the "tech taste" of this big meal has become stronger and
              stronger. This year's Spring Festival Gala stage was the first to
              create a three-layer three-dimensional dance beauty, while using
              flying screen technology to create a 360-degree surround
              landscape, so that the audience can have the ultimate experience
              of "naked eye 3D" in front of the screen.
            </p>
            <router-link to="/" class="more">More</router-link>
          </div>
        </div>
        <div class="industry-list">
          <el-row class="row-bg">
            <!-- <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="8">
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>30</span>
                    2022-2
                  </div>
                </div>
              </div>
            </el-col> -->
            <el-col
              :xs="24"
              :sm="8"
              v-for="(item, key) in detailData[0]"
              :key="key"
            >
              <div class="grid-content bg-purple">
                <div class="industry-list-img"></div>
                <div class="txt">
                  <h2>
                    <router-link class="yichu1" to="/" v-html="item.title">
                      捷豹路虎开发裸眼3D车载显示系统 将有利于自动驾驶
                    </router-link>
                  </h2>
                  <p class="yichu" v-html="item.detail">
                    尽管近年来3D内容已经不再流行，许多制造商已经放弃了3D电视，但是，捷豹路虎正在开发一种信息娱乐系统，该系统利用3D图像，将它们投射到驾驶员和乘客面前。
                    这款3D抬头显示器和信息娱乐系统的主要功能是将实时安全信息投射到驾驶员的视野中，其中包括导航提示，任何潜在障碍和车道偏离警告等等。
                  </p>
                  <div class="time intime">
                    <span>28</span>
                    2020-3
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <el-pagination background layout="prev, pager, next" :total="2">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news",
  data() {
    return {
      detailData: [
        [
          {
            title:
              "Glasses-free 3D, 5G + 8K, virtual reality, unmanned aerial vehicles ,these black technologies in the Spring Festival Evening of 2020.",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Every Spring Festival Gala on New Year's Eve is an appetizing 'audiovisual dinner' on the audience's dinner table. In recent years, the 'tech taste' of this big meal has become stronger and stronger. This year's Spring Festival Gala stage was the first to create a three-layer three-dimensional dance beauty, while using flying screen technology to create a 360-degree surround landscape, so that the audience can have the ultimate experience of 'naked eye 3D' in front of the screen.",
            time: "2020-4-28",
          },
          {
            title: "Why not use glasses-free 3D projection in movie theaters?",
            img: require("../../assets/img/news/news_center_img3.jpg"),
            detail:
              "Some people will find that the glasses-free 3D technology has clearly appeared, but the actual application is very few. Usually, the  glasses-free 3D technology is not seen at all. Even in some large exhibition halls, such as movie theaters, the 3D projection technology is not widely used. . Why not use glasses-free 3D projection in movie theaters?",
            time: "2020-8-16",
          },
        ],
      ],
    };
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.apply {
  padding-top: 3%;
  .news-container {
    text-align: center;
    margin: 0 auto;
    max-width: 1400px;
    .industry {
      .industry-li {
        background-color: #fafafa;
        margin-bottom: 2%;
        position: relative;
        padding-left: 42%;
        margin-bottom: 3%;
        height: 403px;
        .industry-li-img {
          width: 42%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
        }
      }

      .txt {
        color: #666;
        padding: 5% 5% 3% 5%;
        text-align: left;
        p {
          font-size: 15px;
          line-height: 24px;
          height: 72px;
          margin: 5% 0 10% 0;
        }
        .time {
          font-weight: bold;
          float: right;
          text-align: center;
          font-size: 13px;
          color: #0068b7;
          span {
            display: block;
            font-size: 44px;
          }
        }
        h2 {
          clear: both;
          margin: 0;
          font-size: 26px;
          font-weight: normal;
          &::after {
            display: block;
            content: "";
            width: 35px;
            height: 2px;
            background: #0068b7;
            margin: 3% 0 0 0;
            box-sizing: border-box;
          }
          a:hover {
            color: #0068b7;
            text-decoration: none;
          }
        }
        a {
          color: #333;
          text-decoration: none;
          display: block;
        }
        .more {
          display: inline-block;
          position: relative;
          color: #6b6b6b;
          font-size: 12px;
          padding-right: 26px;
          padding-bottom: 5px;
          border-bottom: 1px solid #0068b7;
          &::after {
            position: absolute;
            content: "";
            border: 6px solid transparent;
            bottom: -6px;
            right: -6px;
            border-left-color: #0068b7;
          }
          &:hover {
            color: #0068b7;
          }
        }
      }
    }
    .industry-list {
      margin-top: 20px;
      min-height: 598px;
      .grid-content {
        position: relative;
        padding-right: 25px;
        margin-bottom: 25px;
        .industry-list-img {
          padding-block: 40%;
          background: url("~@/assets/img/news/20220215170324889.jpg");
          background-position: center center;
          // background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }
      .txt {
        background: #f8f8f8;
        text-align: center;
        padding: 5% 3%;
        margin: 0;
        // &::after {
        //   display: block;
        //   content: "";
        //   width: 30px;
        //   height: 2px;
        //   margin: 2% auto 4%;
        //   background: #0068b7;
        // }
        h2 {
          font-size: 18px;
        }
        p {
          font-size: 14px;
          line-height: 24px;
          padding: 0 5%;
          overflow: hidden;
          height: 48px;
          margin: 5% 0;
        }
        .intime {
          color: #fff;
          position: absolute;
          left: 0;
          top: 0;
          width: 97px;

          padding: 2% 0;
          background: #0068b7;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .apply {
    .news-container {
      .industry-list {
        padding: 0 15px;
        .grid-content {
          padding-right: 0;
        }
      }
      .industry {
        .industry-li {
          padding-left: 0;
          height: 0;
        }
        .txt {
          .time {
            float: none;
          }
        }
      }
    }
  }
}
</style>>
