<template>
  <div class="about">
    <header-img :bgImg="require('@/assets/img/news/Banner002187-ban6.jpg')"></header-img>
    <div class="about-box">
      <!-- <div class="title">
        <div class="title-box">
          <div class="product-title">—新闻中心</div>
          <div class="title-flex" ref="activeClick" @click="activeClick">
            <div data-index="1" :class="{ active: this.titleActive == 1 }">
              <span>公司新闻</span>
            </div>
            <div data-index="2" :class="{ active: this.titleActive == 2 }">
              <span>行业新闻</span>
            </div>
          </div>
        </div>
      </div> -->
      <item-contorl :item="item" :titleActive="titleActive" :toRoute="thisRoute"></item-contorl>
       <router-view></router-view>
      <!-- <News v-show="titleActive == 1"></News>
      <industry-news v-show="titleActive == 2"></industry-news> -->
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
import News from "../components/news/news.vue";
import IndustryNews from "../components/news/IndustryNews.vue";
import ItemContorl from "../components/CommonConponent/itemContorl.vue";
export default {
  name: "procude",
  components: {
    headerImg,
    News,
    IndustryNews,
    ItemContorl,
  },
  data() {
    return {
      thisRoute:'/news/index',
      //去url的id值，或默认为 1
      titleActive: this.$route.query.id || 1,
      item: {
        title: "News",
        list: [
          { index: "1", detail: "Commany news" },
          { index: "2", detail: "Industry news" },
        ],
      },
      mun: 12,
      productDataList: [
        [
          {
            id: 1,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 2,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 3,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 12 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 4,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 5,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 6 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
          {
            id: 6,
            img: require("../assets/img/home/Floor002367-img2.jpg"),
            span: { xs: 24, sm: 12 },
            title: "65寸裸眼3D横屏一体机",
            detail:
              "65寸裸眼3DLCD显示屏采用棱镜技术通过全贴合使得液晶和棱镜全面贴合，保证杰出的3D体验...",
          },
        ],
      ],
    };
  },
  methods: {
    activeClick(e) {
      //切换，index
      this.titleActive = e.target.parentElement.dataset.index;
    },
  },
  mounted() {
    // this.titleActive = this.$route.query.id
    console.log("title" + this.titleActive);
  },
  watch: {
    $route(to, from) {
      this.titleActive = to.query.id;
    },
  },
};
</script><style lang="less" scoped>
.about-box {
  min-height: 300px;

  .detail-box {
    padding: 3% 0;
    .detail-container {
      // padding: 0 2%;
      max-width: 1400px;
      margin: 0 auto;
      > div {
        // margin-bottom: 30px;
        height: 427.6px;
        display: block;
        padding: 15px;
        height: 100%;
      }

      .detail-more {
        position: relative;
        background: #f8f8f8;
        padding: 15px;
        height: 100%;
        text-align: left;
        .hover-more {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: rgba(0, 104, 183, 0.9);
          transition: 0.3s;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          i {
            color: #fff;
            position: absolute;
            width: 100%;
            background: url("~@/assets/img/product/Floor002048-detail.png")
              no-repeat center top;
            padding-top: 40px;
            line-height: 20px;
            top: 50%;
            margin-top: -30px;
            text-align: center;
            font-style: normal;
          }
        }
        .detail-more-img {
          width: 100%;
          height: 334px;
          margin-bottom: 10px;
          background-size: contain;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      h5 {
        margin: 0;
        font-size: 18px;
        font-weight: normal;
        line-height: 30px;
        text-transform: uppercase;
        font-family: Arial, Helvetica, sans-serif;
        color: #333;
        display: block;
      }
      span {
        color: #8a8fa0;
        line-height: 24px;
        font-size: 12px;
      }
    }
  }
}
</style>